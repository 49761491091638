import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import logo from "../Resources/logo.png";
import { logout } from "../Redux/AuthReducer/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addDays } from "date-fns";
import ProfileButton from "../Component/ProfileButton";
import logo_small from "../Resources/logo_small.png";
import DatePickerCom from "../Component/DatePickerCom";
import { fetchRawReport } from "../Redux/AppReducer/Action";
import "../Component/rawReport.css";
import Refresh from "../Component/Refresh";
const RawReport = () => {
  const dispatch = useDispatch();
  const rawreport = useSelector((store) => store.app.rawmeterialreport);
  const token = useSelector((store) => store.auth.token);
  const searchRef = useRef();
  const toast = useToast();
  const fetchRawMeterial=()=>{
    dispatch(fetchRawReport({ report: false }, token));
  }
  useEffect(() => {
    fetchRawMeterial();
  }, [dispatch, token]);
  const handleLogout = () => {
    dispatch(logout());
  };
  const [mainState, setMainState] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 0),
  });
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  return (
    <Box p={"20px"} bgColor={"#f0f8f0"}>
      <Flex justifyContent={"space-between"} mb={"10px"}>
        <Link to={"/"}>
          <Image h={"40px"} src={window.innerWidth < 800 ? logo_small : logo} alt="logo" />
        </Link>
        <Flex>
        <Refresh refresh={fetchRawMeterial} />
        <ProfileButton handleLogin={handleLogout} />
        </Flex>
      </Flex>
      {/* <Flex gap={"5px"}>
        <Flex>
          <Center mt={"5px"} mr={"5px"}>
            Select Date :
          </Center>
          <DatePickerCom
            state={state}
            setState={setState}
            mainState={mainState}
            setMainState={setMainState}
          />
        </Flex>
      </Flex> */}
      <Flex gap={"10px"} mt={"10px"} direction={["column","column","row","row"]}>
        {/* <Button colorScheme="blue">Fetch Report</Button> */}
        <Button
          colorScheme="blue"
          onClick={() => {
            dispatch(fetchRawReport({ report: true,code: searchRef.current.value }, token));
          }}
        >
          Generate Report
        </Button>
        <Flex gap={"15px"}>
        <Input onChange={(e)=>{
          if(e.target.value==""){
            dispatch(fetchRawReport({ report: false }, token));
          }
        }}
          ref={searchRef}
          border={"1px solid blue"}
          maxW={"300px"}
          placeholder="Search Products By Codes"
        />
        <Button
          bgColor={"#00FF00"}
          color={"white"}
          _hover={{ bgColor: "#02e302" }}
          onClick={() => {
            if (
              searchRef.current.value == "" ||
              searchRef.current.value == undefined
            ) {
              return toast({
                title: "Invalid Search",
                status: "error",
                duration: 1000,
                isClosable: true,
              });
            }
            dispatch(
              fetchRawReport(
                { report: false, code: searchRef.current.value },
                token
              )
            );
          }}
        >
          Search
        </Button>
        </Flex>
      </Flex>
      <Box h={`${window.innerHeight - 135}px`} overflow={"scroll"}>
        <table
          style={{ width: "100%", color: "white", marginTop: "10px" }}
          className="rawReportTable"
        >
          <thead>
            <tr backgroundColor={"#0c1559"} color={"white"}>
              <th id="reporttable">
                <Center>Product ID</Center>
              </th>
              <th id="reporttable">
                <Center>Unit</Center>
              </th>
              <th id="reporttable">
                <Center>Product Name</Center>
              </th>
              <th id="reporttable">
                <Center>Previous Quantity</Center>
              </th>
              <th id="reporttable" border={"1px solid white"}>
                <Center>Used Quantity</Center>
              </th>
              <th id="reporttable" border={"1px solid white"}>
                <Center>Used Type</Center>
              </th>
              <th id="reporttable" border={"1px solid white"} p={"5px"}>
                <Center>New Quantity</Center>
              </th>
              <th id="reporttable" border={"1px solid white"} p={"5px"}>
                <Center>For Order</Center>
              </th>
              <th id="reporttable" border={"1px solid white"} p={"5px"}>
                <Center>Dealer Name</Center>
              </th>
              <th id="reporttable" border={"1px solid white"} p={"5px"}>
                <Center>Date</Center>
              </th>
              <th id="reporttable" border={"1px solid white"} p={"5px"}>
                <Center>Entried By</Center>
              </th>
            </tr>
          </thead>
          <tbody style={{ color: "black" }}>
            {rawreport?.length > 0 &&
              rawreport?.map((e, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Text textAlign={"center"}>{e.code}</Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>{e.unit.split("- ")[1]}</Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>{e.name}</Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>
                        {e.old_available_quantity}
                      </Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>{e.gap}</Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>{e.useType}</Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>
                        {e.new_available_quantity}
                      </Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>{e.trackingID}</Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>{e.client}</Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>{e.date}</Text>
                    </td>
                    <td>
                      <Text textAlign={"center"}>{e.addedBy}</Text>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default RawReport;
