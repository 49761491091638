import { Box, Center } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import TableCard from "./TableCard";
import "./table.css";

const TableForm = (prop) => {
  const {
    handleUpdateOrder,
    onProgressOpen,
    onEditOpen,
    setProgress,
    tabIndex,
    onDeleteProductOpen,
  } = prop;
  let production_sheet = useSelector((store) => store.app.orderList);
  if (tabIndex == 3) {
    production_sheet = production_sheet.sort((a, b) => b.id - a.id);
  }
  return (
    <Box mt={window.innerWidth <= 800 ? "40px" : "10px"}>
      <link rel="stylesheet" type="text/css" href={"./table.css"} />
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th w="100px" border="1px solid black" p="5px">
              <Center>ID No.</Center>
            </th>
            <th w="100px" border="1px solid black" p="5px">
              <Center>Order Data</Center>
            </th>
            <th>
              <Center>Order Description</Center>
            </th>
            <th style={{ width: "150px" }}>
              <Center>Order Receiving Date</Center>
            </th>
            <th style={{ width: "150px" }}>
              <Center>Expected Delivery Date</Center>
            </th>
            {tabIndex <= 3 || tabIndex == 7 ? null : (
              <th>
                <Center>AWB No.</Center>
              </th>
            )}
            {tabIndex <= 3 || tabIndex == 7 ? null : (
              <th>
                <Center>Delivery Partner</Center>
              </th>
            )}
          </tr>
        </thead>
        <tbody borderTop="1px solid black">
          {production_sheet?.map((ele, i) => {
            return (
              <TableCard
              key={i}
              prop={prop}
              ele={ele}
              handleUpdateOrder={handleUpdateOrder}
              onProgressOpen={onProgressOpen}
              onEditOpen={onEditOpen}
              setProgress={setProgress}
              tabIndex={tabIndex}
              onDeleteProductOpen={onDeleteProductOpen}
            />
            )
          })}
        </tbody>
      </table>
    </Box>
  );
};

export default React.memo(TableForm);
