import {
  Box,
  Center,
  Flex,
  Image,
  Input,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import logo from "../Resources/logo.png";
import axios from "axios";
import ProductBarcode from "../Component/ProductBarcode";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/AuthReducer/Action";
import ProfileButton from "../Component/ProfileButton";
import { Link } from "react-router-dom";
import Refresh from "../Component/Refresh";

const Products = () => {
  const dispatch = useDispatch();
  const [allProduct, setAllProduct] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(16);
  const [searchTerm, setSearchTerm] = useState("");
  const jumpRef = useRef();
  const fetchAllProducts = () => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/purchase/product`,
          { code: "" },
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PURCHASE_KEY}`,
            },
          }
        )
        .then((res) => {
          const data = res.data.data.sort((a, b) => a.code - b.code);
          setAllProduct(data);
          setCurrentPage(1); // Reset to first page on data load
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchAllProducts();
  }, []);

  useEffect(() => {
    const filteredData = allProduct.filter(
      (el) =>
        el.code.includes(searchTerm) ||
        el.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilterProduct(filteredData.sort((a, b) => a.code - b.code));
    setCurrentPage(1); // Reset to first page on filter
  }, [searchTerm, allProduct]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filterProduct.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filterProduct.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Box p={["10px", "10px", "10px", "10px 100px"]}>
      <Flex justifyContent={"space-between"}>
        <Link to={"/"}>
          <Image h={"40px"} src={logo} alt="logo" />
        </Link>
        <Flex gap={"10px"}>
          <Refresh refresh={fetchAllProducts} />
          <ProfileButton handleLogin={handleLogout} />
        </Flex>
      </Flex>
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        border={"1px solid black"}
        mt={"10px"}
        placeholder="Search..."
      />
      <Flex justifyContent={"space-between"} m={"8px 0px"}>
        <Center>
          <Text textAlign={"center"}>
            Result:{" "}
            {filterProduct.length > 0
              ? filterProduct.length
              : allProduct.length}
          </Text>
        </Center>
        <Center>
          <Flex justifyContent={"center"}>
            <IconButton
              icon={<ChevronLeftIcon />}
              onClick={() => handlePageChange(currentPage - 1)}
              isDisabled={currentPage === 1}
              aria-label="Previous Page"
            />
            <Center>
              <Text textAlign={"center"} mx={2} fontSize="lg">
                Page {currentPage} of {totalPages}
              </Text>
            </Center>
            <IconButton
              icon={<ChevronRightIcon />}
              onClick={() => handlePageChange(currentPage + 1)}
              isDisabled={currentPage === totalPages}
              aria-label="Next Page"
            />
          </Flex>
          <Text m={"0px 8px"}>Jump To:</Text>
          <Input
            w={"70px"}
            h={"30px"}
            ref={jumpRef}
            onKeyUp={(e) => {
              if (e.key == "Enter") {
                setCurrentPage(
                  jumpRef.current.value <= 1 ? 1 : Number(jumpRef.current.value)
                );
                jumpRef.current.value = "";
              }
            }}
          />
        </Center>
      </Flex>

      <Box height={"600px"} overflow={"scroll"} overflowX={"hidden"}>
        <Table>
          <Thead>
            <Tr>
              <Th>
                <Center>Code</Center>
              </Th>
              <Th>
                <Center>Name</Center>
              </Th>
              <Th>
                <Center>Actions</Center>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentItems.length > 0
              ? currentItems.map((e,i) => (
                  <Tr key={i}>
                    <Td>
                      <Center>{e.code}</Center>
                    </Td>
                    <Td>
                      <Center>{e.name.toUpperCase()}</Center>
                    </Td>
                    <Td>
                      <ProductBarcode
                        value={e.code}
                        name={e.name.toUpperCase()}
                      />
                    </Td>
                  </Tr>
                ))
              : filterProduct.map((e) => (
                  <Tr key={e.code}>
                    <Td>
                      <Center>{e.code}</Center>
                    </Td>
                    <Td>
                      <Center>{e.name.toUpperCase()}</Center>
                    </Td>
                    <Td>
                      <ProductBarcode
                        value={e.code}
                        name={e.name.toUpperCase()}
                      />
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Products;
