import {
  Box,
  Flex,
  Image,
} from "@chakra-ui/react";

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/AuthReducer/Action";
import logo from "../Resources/logo.png";
import ReportTable from "../Component/Report/ReportTable";
import ReportFilters from "../Component/Report/ReportFilters";
import ReportOverview from "../Component/Report/ReportOverview";
import { generatedTime } from "../Redux/AppReducer/Action";
import ProfileButton from "../Component/ProfileButton";
import { Link } from "react-router-dom";
const Report = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    dispatch(generatedTime());
  }, [dispatch]);
  return (
    <Box p={"20px"}>
      <Box bgColor={"#fafafa"} p={"10px 2px"} position={"sticky"} top={0} zIndex={9999999999} w={"100%"}>
      <Flex justifyContent={"space-between"} mb={"10px"}>
        <Link to={"/"}>
          <Image h={"40px"} src={logo} alt="logo" />
        </Link>
        <ProfileButton handleLogin={handleLogout} />
      </Flex>
      <ReportFilters /> 
      </Box>
      
      <ReportOverview />
      <ReportTable />
    </Box>
  );
};

export default Report;
