import * as types from "./ActionTypes";
const initialState = {
  userList: [],
  isLoading: false,
  isError: false,
};

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.USER_REQUEST: {
      return { ...state, userList: [],isError: false, isLoading: true };
    }
    case types.USER_SUCCESS: {
      return { ...state, userList: payload,isError: false, isLoading: false };
    }
    case types.USER_FAILURE: {
      return { ...state, isLoading: false, userList: [], isError: false };
    }
    default:
      return state;
  }
};
