import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Button,
  SimpleGrid,
  useToast,
  Select,
} from "@chakra-ui/react";
import default_product_image from "../Resources/default_product_image.png";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUploadComponent from "./ImageUploadComponent";
import axios from "axios";
import { loaderUpdate } from "../Redux/AppReducer/Action";

const PaymentImageUpload = ({ isOpen, onClose }) => {
  const initImage = [
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
    default_product_image,
  ];
  const [selectedImage, setSelectedImage] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [displayImage, setdisplayImage] = useState(initImage);
  const dispatch = useDispatch();
  const paymentState = useSelector((store) => store.app.paymentUpdate);
  const toast = useToast();
  const handleImageUpload = (event, pos) => {
    let pics = selectedImage;
    let blob = [...displayImage];
    if (event.target.files[0] !== undefined) {
      pics[selectedImage.length] = {
        pic: event.target.files[0],
        position: pos + 1,
      };
      blob[pos] = URL.createObjectURL(event.target.files[0]);
      setSelectedImage(pics);
      setdisplayImage(blob);
      event.target.value = "";
    }
  };
  const handleDeleteImage = (e, pos) => {
    e.preventDefault();
    let pics = selectedImage.filter((ele, i) => i !== pos);
    let blob = [...displayImage];
    URL.revokeObjectURL(blob[pos]);
    blob[pos] = default_product_image;
    setSelectedImage(pics);
    setdisplayImage(blob);
  };
  const token = useSelector((store) => store.auth.token);
  let checkedList = useSelector((store) => store.app.orderList).filter(
    (e) => e.checked == true
  )[0];
  const cleanupOnClose = () => {
    checkedList.description = checkedList.description.map((ele) => {
      ele.stage.pop();
      return ele;
    });
    onClose();
  };
  const handlePaymentUpload = async () => {
    if (paymentType == "") {
      toast({
        title: "Please Select Payment Type",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    } else if (paymentType == "online" && selectedImage.length <= 0) {
      toast({
        title: "Please upload Payment Proof",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    dispatch(loaderUpdate(true));
    const formData = new FormData();
    if (paymentType == "online") {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(
          "myFile",
          selectedImage[i].pic,
          `${checkedList.orderID.split("-")[0]}_pay_${
            selectedImage[i].position
          }.jpg`
        );
      }
    }

    formData.append(
      "orderdescription",
      JSON.stringify(checkedList.description)
    );
    formData.append("type", paymentType);
    axios
      .patch(
        `${process.env.REACT_APP_BACKEND_URL}/sheet/updateorder/${checkedList.id}?stage=${paymentState}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        toast({
          title: "Request Sent to Admin",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        dispatch(loaderUpdate(false));
        onClose();
      })
      .catch((err) => {
        dispatch(loaderUpdate(false));
        cleanupOnClose();
      });
  };
  return (
    <Box>
      <Modal isCentered isOpen={isOpen} onClose={cleanupOnClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Payment Proof</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Select
                onChange={(e) => {
                  setPaymentType(e.target.value);
                  checkedList.description = checkedList.description.map(
                    (el) => {
                      console.log(el.stage);
                      el.stage[el.stage.length - 1].paymenttype =
                        e.target.value;
                      return el;
                    }
                  );
                }}
              >
                <option hidden>Payment Type</option>
                <option value={"cash"}>Cash</option>
                <option value={"online"}>Online</option>
              </Select>
              <SimpleGrid
                columns={4}
                gap={"5px"}
                hidden={paymentType != "online"}
              >
                <ImageUploadComponent
                  position={0}
                  handleDeleteImage={handleDeleteImage}
                  handleImageUpload={handleImageUpload}
                  displayImage={displayImage}
                />
                <ImageUploadComponent
                  position={1}
                  handleDeleteImage={handleDeleteImage}
                  handleImageUpload={handleImageUpload}
                  displayImage={displayImage}
                />
                <ImageUploadComponent
                  position={2}
                  handleDeleteImage={handleDeleteImage}
                  handleImageUpload={handleImageUpload}
                  displayImage={displayImage}
                />
                <ImageUploadComponent
                  position={3}
                  handleDeleteImage={handleDeleteImage}
                  handleImageUpload={handleImageUpload}
                  displayImage={displayImage}
                />
              </SimpleGrid>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handlePaymentUpload}>
              Submit
            </Button>
            <Button colorScheme="red" onClick={cleanupOnClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PaymentImageUpload;
