import React, { useEffect, useRef, useState } from "react";
import logo from "../Resources/logo.png";
import {
  Box,
  Button,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Flex,
  Text,
  Input,
  Center,
  useToast,
  Select,
  Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { initialPurchase, updateRefNo } from "../Redux/PurchaseReducer/Action";
import PurchaseTable from "../Component/Purchase/PurchaseTable";
import axios from "axios";
import { logout, purchaseAuth } from "../Redux/AuthReducer/Action";
import ProfileButton from "../Component/ProfileButton";
import { Link } from "react-router-dom";
const Purchase = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const finalDate = `${year}-${month}-${day}`;
  const navigate = useNavigate();
  const toast = useToast();
  const validateRef = useRef();
  const [warehouses, setWarehouses] = useState(16);
  const [supplier, setSupplier] = useState(18);
  const dispatch = useDispatch();
  const referenceID = useSelector((store) => store.purchase.referenceID);
  const warehouse = useSelector((store) => store.purchase.warehouse);
  const suppliers = useSelector((store) => store.purchase.suppliers);
  const purchaseauth = useSelector((store) => store.auth.purchaseauth);
  const [tableData, setTableData] = useState([]);
  const {
    isOpen: isValidationOpen,
    onOpen: onValidationOpen,
    onClose: onValidationClose,
  } = useDisclosure();
  useEffect(() => {
    if (purchaseauth !== process.env.REACT_APP_PURCHASE_KEY) {
      onValidationOpen();
    }
    dispatch(initialPurchase());
  }, [dispatch,onValidationOpen,purchaseauth]);
  const handleValidate = () => {
    if (validateRef.current.value === process.env.REACT_APP_PURCHASE_KEY) {
      dispatch(purchaseAuth(validateRef.current.value));
      onValidationClose();
    } else {
      toast({
        title: "Incorrect Password",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handlePurchase = () => {
    const payload = {
      warehouseID: warehouses,
      supplierID: supplier,
      date: finalDate,
      refNo: referenceID,
      product: tableData,
    };
    if (tableData.length > 0) {
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/purchase/createpurchase`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_PURCHASE_KEY}`,
            },
          }
        )
        .then((res) => {
          toast({
            title: "Stock updated",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          dispatch(updateRefNo(res.data.insertId));
          setTableData([]);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast({
        title: "Add Some Product First",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <Box p={"20px 10px"}>
      <AlertDialog
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
        isOpen={isValidationOpen}
        onClose={onValidationClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Validation
            </AlertDialogHeader>

            <AlertDialogBody>
              <Flex gap={"10px"}>
                <Center>Password:</Center>
                <Input type="password"
                  ref={validateRef}
                  placeholder="Enter Password"
                  onKeyUp={(e) => {
                    if (e.key == "Enter") {
                      handleValidate();
                    }
                  }}
                />
              </Flex>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="red" onClick={handleValidate}>
                Validate
              </Button>
              <Button ml={3} onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex justifyContent={"space-between"} mb={"10px"}>
        <Link to={"/"}>
          <Image h={"40px"} src={logo} alt="logo" />
        </Link>

        <ProfileButton handleLogin={handleLogout} />
      </Flex>
      <Flex
        p={"15px"}
        justifyContent={"space-between"}
        bgColor={"#ecf0f5"}
        direction={["column", "column", "row", "row"]}
      >
        <Stack p={0}>
          <Text>Date</Text>
          <Text w={"250px"} p={"5px 10px"} border={"1px solid gray"}>
            {finalDate}
          </Text>
        </Stack>
        <Stack p={0}>
          <Text>Warehouse</Text>
          <Box w={"250px"} p={"5px 0px"} pr={"0px"} border={"1px solid gray"}>
            <Select
              value={warehouses}
              onChange={(e) => setWarehouses(Number(e.target.value))}
              w={"249px"}
              variant={"unstyled"}
              border={"0px solid gray"}
            >
              <option hidden>Select Warehouse</option>
              {warehouse?.map((e) => {
                return (
                  <option value={e.warehouse_id}>{e.warehouse_name}</option>
                );
              })}
            </Select>
          </Box>
        </Stack>
        <Stack p={0}>
          <Text>Reference No</Text>
          <Text w={"250px"} p={"5px 10px"} border={"1px solid gray"}>
            {referenceID}
          </Text>
        </Stack>
        <Stack p={0}>
          <Text>Supplier</Text>
          <Box w={"250px"} p={"5px 0px"} pr={"0px"} border={"1px solid gray"}>
            <Select
              value={supplier}
              onChange={(e) => setSupplier(Number(e.target.value))}
              w={"249px"}
              variant={"unstyled"}
              border={"0px solid gray"}
            >
              <option hidden>Select Supplier</option>
              {suppliers?.map((e) => {
                return <option value={e.supplier_id}>{e.supplier_name}</option>;
              })}
            </Select>
          </Box>
        </Stack>
      </Flex>
      <PurchaseTable
        tableData={tableData}
        setTableData={setTableData}
        referenceID={referenceID}
      />
      <Flex mt={"10px"}>
        <Button colorScheme="green" onClick={handlePurchase}>
          Submit
        </Button>
      </Flex>
    </Box>
  );
};
export default Purchase;
