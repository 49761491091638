import React from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { capitalizeWords } from "./capitalWords";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProduct,
  editProduct,
  fetchProduction,
} from "../Redux/AppReducer/Action";
import {
  admin_user,
  allowed_stages_for_new,
  exclude_stage,
  table_filter,
  universal_stages,
} from "../universal_variable";
import CustomSlider from "./CustomSlider";
import BarcodeGen from "./BarcodeGen";
import ImagePopOver from "./ImagePopOver";
import DisplayRawMeterials from "./DisplayRawMeterials";

const TableProductData = ({
  handleUpdateOrder,
  prop,
  tabIndex,
  setProgress,
  selectRef,
  onProgressOpen,
  onEditOpen,
  hide,
  elem,
  ele,
  index,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.auth.token);
  const user = useSelector((store) => store.auth.user);
  const datesAreEqual = (date1, date2, lastStage) => {
    // Split date strings into day, month, and year
    let [day1, month1, year1] = date1.split("/");
    let [day2, month2, year2] = date2.split("/");

    // Convert parts to integers
    day1 = parseInt(day1, 10);
    month1 = parseInt(month1, 10) - 1; // Month is 0-indexed in Date object (0-11)
    year1 = parseInt(year1, 10);

    day2 = parseInt(day2, 10);
    month2 = parseInt(month2, 10) - 1;
    year2 = parseInt(year2, 10);

    // Create Date objects for comparison
    let dateObject1 = new Date(year1, month1, day1);
    let dateObject2 = new Date(year2, month2, day2);
    // Compare dates
    if (allowed_stages_for_new.includes(lastStage)) {
      return dateObject1.getTime() !== dateObject2.getTime();
    } else {
      return true;
    }
  };
  const {
    isOpen: israwDisplayOpen,
    onOpen: onrawDisplayOpen,
    onClose: onrawDisplayClose,
  } = useDisclosure();
  const finalStage = elem?.stage?.filter(
    (e) => exclude_stage.includes(e.stage) == false
  );
  const paymentStage = elem?.stage
    ?.filter((e) => ["pending", "accept"].includes(e.request))
    .reverse();
  const last_stage = finalStage[finalStage.length - 1]?.stage;
  const last_stage_date = finalStage[finalStage.length - 1]?.date;
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();
  const todayDate = `${dd}/${mm}/${yyyy}`;
  return (
    <Box
      key={index}
      mr="10px"
      borderBottom={
        hide.length > 1 && index != hide.length - 1 ? "1px solid #ddd" : "none"
      }
      w={window.innerWidth <= 800 ? `${window.innerWidth - 40}px` : null}
    >
      <DisplayRawMeterials
        data={elem?.raw?.rawmeterials}
        isOpen={israwDisplayOpen}
        onClose={onrawDisplayClose}
      />
      <Text
        hidden={
          ele?.paymentimg?.length <= 0
            ? true
            : tabIndex <= 2
            ? true
            : user.usertype == "bc"
            ? false
            : !admin_user.includes(user.email)
        }
        ml={"10px"}
        fontWeight={"bold"}
      >
        Payment Proof of this Order
      </Text>
      <Stack
        ml={"10px"}
        borderBottom={"1px solid black"}
        pb={"10px"}
        pt={"10px"}
        hidden={
          ele?.paymentimg?.length <= 0
            ? true
            : tabIndex <= 2
            ? true
            : user.usertype == "bc"
            ? false
            : !admin_user.includes(user.email)
        }
      >
        <Flex gap={"10px"}>
          {ele?.paymentimg?.map((e) => {
            const url = `${process.env.REACT_APP_BACKEND_URL}/paymentimage/${e}`;
            return <ImagePopOver url={url} e={e} />;
          })}
        </Flex>
      </Stack>
      <Flex
        w={window.innerWidth <= 800 ? `${window.innerWidth - 40}px` : null}
        mt="5px"
        justifyContent="space-between"
        hidden={!user.first_name}
      >
        <Center>
          <Text>
            <Text
              as={"span"}
              m="10px"
              mr="0px"
              color="#218560"
              fontWeight="bold"
            >
              Product {elem.position + 1}
            </Text>
            <Text
              fontWeight="bold"
              color={"red"}
              as={"span"}
              hidden={datesAreEqual(
                todayDate.toString(),
                last_stage_date.toString(),
                last_stage
              )}
            >
              (NEW)
            </Text>
          </Text>
        </Center>
        <Flex gap="5px">
          <Box hidden={prop.queueDispatchment || prop.payment || prop.cancel}>
            <Select
              bgColor={"white"}
              ref={selectRef}
              maxW="180px"
              isDisabled={table_filter.complete.includes(last_stage)}
              value={last_stage}
              onChange={(e) =>
                handleUpdateOrder(
                  ele.description,
                  e.target.value,
                  elem.position,
                  ele.id
                )
              }
            >
              {universal_stages.map((el, i) => (
                <option
                  key={i}
                  hidden={el.hidden === true}
                  disabled={
                    el.label === true || el?.blockedtab?.includes(tabIndex)
                  }
                  value={el.value}
                >
                  {el.name}
                </option>
              ))}
            </Select>
          </Box>
          <Menu placement="bottom-end">
            <MenuButton
              as={Button}
              bgColor={window.innerWidth <= 800 ? "white" : null}
            >
              ...
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setProgress(elem?.stage);
                  onProgressOpen();
                }}
                _hover={{ bgColor: "blue", color: "white" }}
              >
                Show Logs
              </MenuItem>
              <MenuItem _hover={{ bgColor: "blue", color: "white" }}>
                <BarcodeGen
                  client={ele?.orderData?.dealer?.distributor_name}
                  value={ele?.trackingID}
                  name={elem?.title}
                  pos={index + 1}
                />
              </MenuItem>
              <MenuItem _hover={{ bgColor: "blue", color: "white" }}>
                <BarcodeGen
                  tag={false}
                  client={ele?.orderData?.dealer?.distributor_name}
                  address={ele?.orderData?.dealer?.address}
                  value={ele?.trackingID}
                  name={elem?.title}
                  pos={index + 1}
                />
              </MenuItem>
              <MenuItem
                hidden={
                  table_filter.complete.includes(last_stage) ||
                  tabIndex == 3 ||
                  prop.cancel
                }
                _hover={{ bgColor: "blue", color: "white" }}
                onClick={() =>
                  handleUpdateOrder(
                    ele.description,
                    "update shipping",
                    index,
                    ele.id
                  )
                }
              >
                Update Shipping
              </MenuItem>
              <MenuItem
                _hover={{ bgColor: "blue", color: "white" }}
                hidden={elem.raw == undefined}
                onClick={onrawDisplayOpen}
              >
                Used Raw Meterials
              </MenuItem>
              <MenuItem
                hidden={
                  table_filter.complete.includes(last_stage) || tabIndex > 2
                }
                _hover={{ bgColor: "blue", color: "white" }}
                onClick={() => {
                  dispatch(
                    editProduct({
                      orderID: ele.orderID,
                      description: ele.description,
                      id: ele.id,
                      position: elem.position,
                    })
                  );
                  onEditOpen();
                }}
              >
                Edit Product
              </MenuItem>
              <MenuItem
                hidden={
                  table_filter.complete.includes(last_stage) || tabIndex > 2
                }
                onClick={() => {
                  dispatch(deleteProduct(index, ele.id, token)).then(() => {
                    dispatch(fetchProduction());
                  });
                }}
                _hover={{ bgColor: "red", color: "white" }}
              >
                Delete Product
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <Text
        hidden={
          tabIndex <= 2
            ? true
            : user.usertype == "bc"
            ? false
            : !admin_user.includes(user.email)
        }
        textAlign={"center"}
        mt={"5px"}
        fontWeight={"bold"}
        as={"span"}
      >
        <Center>
          Payment Type:
          <Text
            as={"span"}
            ml={"5px"}
            color={
              paymentStage[0]?.request == "reject"
                ? "red"
                : paymentStage[0]?.request == "accept"
                ? "green"
                : paymentStage[0]?.request == "pending"
                ? "orange"
                : "black"
            }
          >
            {capitalizeWords(paymentStage[0]?.stage)} (
            {capitalizeWords(paymentStage[0]?.paymenttype) || "Empty"})
          </Text>
        </Center>
      </Text>
      <Text
        m="10px"
        mr="0px"
        color="red"
        fontWeight="bold"
        fontSize="20px"
        hidden={!table_filter.cancel.includes(last_stage)}
      >
        {table_filter.cancel.includes(finalStage[finalStage.length - 1].reason)
          ? ""
          : `${finalStage[finalStage.length - 1].stage} Due To`}{" "}
        {capitalizeWords(
          finalStage[finalStage.length - 1].reason?.toLowerCase()
        )}
      </Text>
      <Text
        m="10px"
        mr="0px"
        color="red"
        fontWeight="bold"
        fontSize="20px"
        hidden={last_stage !== "hold"}
      >
        {finalStage[finalStage.length - 1].reason
          ?.toLowerCase()
          ?.includes("hold")
          ? ""
          : "Hold Due To"}{" "}
        {capitalizeWords(
          finalStage[finalStage.length - 1].reason?.toLowerCase()
        )}
      </Text>
      <Text w={window.innerWidth <= 800 ? "335px" : "400px"} m="10px">
        {elem?.title}
      </Text>
      <Text maxW={"400px"} mt="15px" m="10px" whiteSpace="pre-wrap">
        {elem?.description}
      </Text>
      <CustomSlider data={elem?.image} />
    </Box>
  );
};

export default TableProductData;
