import React, { useRef, useState, useCallback, useMemo } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import in_progress from "../Resources/in_progress.png";
import on_hold from "../Resources/on_hold_icon.png";
import completed from "../Resources/completed.png";
import { FaLink } from "react-icons/fa";
import { MdOutlineContentCopy } from "react-icons/md";
import { Link } from "react-router-dom";
import { capitalizeWords } from "./capitalWords";
import { useDispatch, useSelector } from "react-redux";
import {
  holdUpdate,
  updateCheckBox,
  updateOrderDetails,
} from "../Redux/AppReducer/Action";
import {
  courier_partner,
  exclude_stage,
  hold_list,
  table_filter,
  universal_stages,
} from "../universal_variable";
import TrackTableCard from "./TrackTableCard";
import TableProductData from "./TableProductData";

const TableCard = ({
  prop,
  ele,
  handleUpdateOrder,
  onProgressOpen,
  onEditOpen,
  tabIndex,
  setProgress,
}) => {
  const {
    isOpen: isShippingAlertOpen,
    onOpen: onShippingAlertOpen,
    onClose: onShippingAlertClose,
  } = useDisclosure();
  const {
    isOpen: isHoldgAlertOpen,
    onOpen: onHoldAlertOpen,
    onClose: onHoldAlertClose,
  } = useDisclosure();
  const {
    isOpen: isCancelOpen,
    onOpen: onCancelOpen,
    onClose: onCancelClose,
  } = useDisclosure();
  const toast = useToast();
  const selectRef = useRef();
  const courier = useRef(null);
  const cancelRef = useRef(null);
  const holdRef = useRef(null);
  const awb = useRef(null);
  const dispatch = useDispatch();
  const holdValue = useSelector((store) => store.app.hold);
  const token = useSelector((store) => store.auth.token);
  const user = useSelector((store) => store.auth.user);
  const [copylink, setCopyLink] = useState(false);
  const [tempCourier, setTempCourier] = useState("");
  const all_last_stage =
    ele.description[0]?.stage[ele.description[0]?.stage.length - 1]?.stage;
  let hide_universal_select_tag = false;
  ele.description.forEach((e) => {
    if (e.stage[e.stage.length - 1]?.stage != all_last_stage) {
      hide_universal_select_tag = true;
    }
  });
  const genLink = useMemo(
    () => `https://rumaenterprise.com/track/?id=${ele?.trackingID}`,
    [ele?.trackingID]
  );
  let hide = ele.description.filter((elem, i) => {
    if (!elem?.stage) return false;
    const finalStage = elem?.stage?.filter(
      (e) => !exclude_stage.includes(e.stage)
    );
    const last_stage = finalStage[finalStage.length - 1]?.stage;
    return (
      (prop.active && table_filter.active.includes(last_stage)) ||
      (prop.hold && table_filter.hold.includes(last_stage)) ||
      (prop.queueDispatchment &&
        table_filter.queueDispatch.includes(last_stage)) ||
      (prop.payment && table_filter.payment.includes(last_stage)) ||
      (prop.readyDispatchment &&
        table_filter.readyDispatch.includes(last_stage)) ||
      (prop.shipped && table_filter.shipped.includes(last_stage)) ||
      (prop.complete && table_filter.complete.includes(last_stage)) ||
      (prop.cancel && table_filter.cancel.includes(last_stage))
    );
  });
  let courierData = ele?.courier?.filter((elem, i) => {
    const desc = ele?.description[i];
    if (!desc?.stage) return false;
    const finalStage = desc?.stage?.filter(
      (e) => !exclude_stage.includes(e.stage)
    );
    const last_stage = finalStage[finalStage.length - 1]?.stage;
    return (
      (prop.active && table_filter.active.includes(last_stage)) ||
      (prop.hold && table_filter.hold.includes(last_stage)) ||
      (prop.queueDispatchment &&
        table_filter.queueDispatch.includes(last_stage)) ||
      (prop.payment && table_filter.payment.includes(last_stage)) ||
      (prop.readyDispatchment &&
        table_filter.readyDispatch.includes(last_stage)) ||
      (prop.shipped && table_filter.shipped.includes(last_stage)) ||
      (prop.complete && table_filter.complete.includes(last_stage)) ||
      (prop.cancel && table_filter.cancel.includes(last_stage))
    );
  });

  const handleCopyLink = useCallback(async () => {
    setCopyLink(true);
    setTimeout(() => setCopyLink(false), 2000);

    if (navigator.clipboard) {
      try {
        const permissionGranted = await navigator.permissions.query({
          name: "clipboard-write",
        });
        if (
          permissionGranted.state === "granted" ||
          permissionGranted.state === "prompt"
        ) {
          await navigator.clipboard.writeText(genLink);
          toast({
            title: "Text copied to clipboard",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
        } else {
          throw new Error("Clipboard-write permission denied");
        }
      } catch (err) {
        toast({
          title: "Could not copy text",
          description: err.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Clipboard API not supported",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [genLink, toast]);
    const handleUniversalShipping = () => {
      ele.description = ele?.description?.map((elem) => {
        elem.stage[elem.stage.length - 1].courier = tempCourier;
        elem.stage[elem.stage.length - 1].awb = awb.current.value;
        return elem;
      });
      const payload = ele[0].stage[ele[0].stage.length - 1];
      dispatch(
        updateOrderDetails(
          JSON.stringify(ele.description),
          ele.id,
          token,
          "shipping booked",
          payload
        )
      ).then(() => {
        onShippingAlertClose();
        toast({
          title: "Stage Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      });
    };
    const handleUniversalCancel = () => {
      ele.description = ele.description.map((elem) => {
        elem.stage[elem.stage.length - 1].reason = cancelRef.current.value;
        return elem;
      });
      const payload =
        ele.description[0].stage[ele.description[0].stage.length - 1];
      dispatch(
        updateOrderDetails(
          JSON.stringify(ele.description),
          ele.id,
          token,
          "cancel",
          payload
        )
      ).then(() => {
        onCancelClose();
        toast({
          title: "Stage Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      });
    };
    const handleUniversalhold = () => {
      ele.description = ele.description.map((elem) => {
        elem.stage[elem.stage.length - 1].reason =
          holdValue == "others" ? holdRef.current.value : holdValue;
        return elem;
      });
      const payload =
        ele.description[0].stage[ele.description[0].stage.length - 1];
      dispatch(
        updateOrderDetails(
          JSON.stringify(ele.description),
          ele.id,
          token,
          "hold",
          payload
        )
      ).then(() => {
        onHoldAlertClose();
        toast({
          title: "Stage Updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      });
    };
    const handleUniversalStage = (value) => {
      try {
        const currentDate = new Date().toISOString().split("T")[0];
        const newDate = new Date();
        let hours = newDate.getHours();
        const minutes = newDate.getMinutes();
        const seconds = newDate.getSeconds();
        const amPm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;
        const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${amPm}`;
        const date = currentDate.split("-").reverse().join("/");
        ele.description = ele.description.map((elem) => {
          elem.stage.push({
            date: date,
            time: formattedTime,
            stage: value,
            updateBy: user.first_name,
          });
          return elem;
        });
        if (value == "shipping booked") {
          onShippingAlertOpen();
        } else if (value == "hold") {
          onHoldAlertOpen();
        } else if (value == "cancel") {
          onCancelOpen();
        } else {
          dispatch(
            updateOrderDetails(
              JSON.stringify(ele.description),
              ele.id,
              token,
              value
            )
          ).then(() => {
            toast({
              title: "Stage Updated",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

  return (
    <tr hidden={hide.length <= 0}>
      {!hide_universal_select_tag ? (
        <>
          <AlertDialog
            motionPreset="slideInBottom"
            onClose={onShippingAlertClose}
            isOpen={isShippingAlertOpen}
            isCentered
          >
            <AlertDialogOverlay />

            <AlertDialogContent>
              <AlertDialogHeader>Book Shipping</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                <Select
                  ref={courier}
                  onChange={(e) => {
                    setTempCourier(e.target.value);
                  }}
                >
                  <option hidden>Select Courier</option>
                  {courier_partner.map((ele) => {
                    return (
                      <option key={ele} value={ele.toLocaleLowerCase()}>
                        {ele}
                      </option>
                    );
                  })}
                </Select>
                {tempCourier == "transport" ? (
                  <Textarea
                    ref={awb}
                    mt={"20px"}
                    placeholder="Enter AWB No"
                    resize={"none"}
                  />
                ) : (
                  <Input ref={awb} mt={"20px"} placeholder="Enter AWB No" />
                )}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button colorScheme="green" onClick={handleUniversalShipping}>
                  Submit
                </Button>
                <Button onClick={onShippingAlertClose} colorScheme="red" ml={3}>
                  Cancel
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <AlertDialog
            motionPreset="slideInBottom"
            onClose={onHoldAlertClose}
            isOpen={isHoldgAlertOpen}
            isCentered
          >
            <AlertDialogOverlay />

            <AlertDialogContent>
              <AlertDialogHeader>Hold Product</AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                <Select
                  onChange={(e) => {
                    dispatch(holdUpdate(e.target.value));
                  }}
                >
                  {hold_list.map(({ name, hidden }) => {
                    return (
                      <option
                        key={name}
                        hidden={hidden == true ? true : false}
                        value={name.toLocaleLowerCase()}
                      >
                        {name}
                      </option>
                    );
                  })}
                </Select>
                <Textarea
                  ref={holdRef}
                  hidden={holdValue !== "others"}
                  mt={"10px"}
                  resize={"none"}
                />
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button colorScheme="green" onClick={handleUniversalhold}>
                  Submit
                </Button>
                <Button onClick={onShippingAlertClose} colorScheme="red" ml={3}>
                  Cancel
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <Modal isCentered isOpen={isCancelOpen} onClose={onCancelClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Reason to Cancel</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Textarea
                  ref={cancelRef}
                  resize={"none"}
                  placeholder="Enter Reason"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="green"
                  mr={3}
                  onClick={handleUniversalCancel}
                >
                  Submit
                </Button>
                <Button colorScheme="red" onClick={onCancelClose}>
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      ) : null}
      <td>
        <Center>
          <Flex gap="5px" textAlign={"center"}>
            <Center>
              <Checkbox
                hidden={!user.first_name || prop.cancel}
                variant="unstyled"
                isChecked={ele.checked}
                border="0px solid gray"
                onChange={(e) => dispatch(updateCheckBox(ele.id))}
              />
            </Center>
            <Center>{ele.id}</Center>
          </Flex>
        </Center>
      </td>
      <td style={{ width: window.innerWidth <= 800 ? null : "300px" }}>
        <Flex direction="column">
          <Text fontWeight="bold" fontSize="18px" mt="10px">
            <Center>Dealer/School:</Center>
          </Text>
          <Text textAlign="center">
            {ele?.orderData?.dealer?.distributor_name}(
            {ele?.orderData?.dealer?.distributor_code})
          </Text>
        </Flex>
        <Flex direction="column">
          <Text fontWeight="bold" fontSize="18px">
            <Center>Address:</Center>
          </Text>
          <Text textAlign="center">{ele?.orderData?.dealer?.address}</Text>
        </Flex>
        <Flex direction="column">
          <Text fontWeight="bold" fontSize="18px">
            <Center>Order taken by:</Center>
          </Text>
          <Text textAlign="center">{ele?.orderData?.orderBy?.biller_name}</Text>
        </Flex>
        <Flex direction="column">
          <Text fontWeight="bold" fontSize="18px">
            <Center>Tracking ID:</Center>
          </Text>
          <Text
            textAlign="center"
            fontSize="20px"
            color="red"
            fontWeight="bold"
          >
            {ele?.trackingID}
          </Text>
        </Flex>
        <Flex direction="column" mt="10px">
          <Link to={genLink} target="_blank">
            <Text
              fontSize="14px"
              textAlign="center"
              color="blue"
              textDecoration="underline"
              fontWeight="bold"
            >
              {genLink}
            </Text>
          </Link>
          <Center>
            <Button
              mt="5px"
              bgColor="#00FF00"
              borderRadius="0px"
              fontSize="17px"
              leftIcon={copylink ? <MdOutlineContentCopy /> : <FaLink />}
              _hover={{ bgColor: "#218560", color: "white" }}
              _active={{ bgColor: "" }}
              onClick={handleCopyLink}
            >
              {copylink ? "COPIED!" : "COPY LINK"}
            </Button>
          </Center>
          <Center>
            <Box
              hidden={prop.queueDispatchment || prop.payment || prop.cancel||hide_universal_select_tag}
              mt={"10px"}
              mb={"10px"}
            >
              <Select
                bgColor={"white"}
                ref={selectRef}
                isDisabled={table_filter.complete.includes(all_last_stage)}
                onChange={(e) => {
                  handleUniversalStage(e.target.value);
                }}
                defaultValue={all_last_stage}
              >
                {universal_stages.map((el, i) => (
                  <option
                    key={i}
                    hidden={el.hidden === true}
                    disabled={
                      el.label === true || el?.blockedtab?.includes(tabIndex)
                    }
                    value={el.value}
                  >
                    {el.name}
                  </option>
                ))}
              </Select>
            </Box>
          </Center>
          {prop.active && (
            <Center>
              <Image
                h={"60px"}
                mt="20px"
                mb="10px"
                p="0px 20px"
                src={in_progress}
                alt="progress"
              />
            </Center>
          )}
          {prop.complete && (
            <Center>
              <Image
                h={"90px"}
                mt="20px"
                mb="10px"
                p="0px 60px"
                src={completed}
                alt="complete"
              />
            </Center>
          )}
          {prop.hold && (
            <Center>
              <Image
                mt="20px"
                h={"60px"}
                mb="10px"
                p="0px 20px"
                src={on_hold}
                alt="hold"
              />
            </Center>
          )}
        </Flex>
      </td>
      <td>
        {hide?.map((elem, index) => {
          return (
            <TableProductData
              setProgress={setProgress}
              tabIndex={tabIndex}
              handleUpdateOrder={handleUpdateOrder}
              onProgressOpen={onProgressOpen}
              prop={prop}
              onEditOpen={onEditOpen}
              selectRef={selectRef}
              hide={hide}
              elem={elem}
              ele={ele}
              index={index}
            />
          );
        })}
      </td>
      <td>
        <Center>
          <Text fontWeight="bold">{ele.dates.orderDate}</Text>
        </Center>
      </td>
      <td>
        <Center>
          <Text fontWeight="bold">{ele.dates.expectedDate}</Text>
        </Center>
      </td>
      {tabIndex <= 3 || tabIndex == 7 ? null : (
        <td>
          {courierData?.map(({ courier, awb }, i) => {
            const data = courierData?.filter(
              (el) =>
                el.courier === courierData?.[0].courier &&
                el.awb === courierData?.[0].awb
            );
            const flag = data.length === courierData?.length;
            if (prop.hold) {
              return <></>;
            }
            return (
              <Box key={i} w="180px" p="0px 5px" hidden={i > 0 && flag}>
                <Text fontWeight="bold" color="red" hidden={flag}>
                  {courier && `Product ${i + 1}`}
                </Text>
                <Text whiteSpace="pre-wrap" textAlign={"center"}>
                  {awb == "" ? "Not Generated Yet" : courier && awb}
                </Text>
                <TrackTableCard courier={courier} awb={awb} />
              </Box>
            );
          })}
        </td>
      )}
      {tabIndex <= 3 || tabIndex == 7 ? null : (
        <td>
          {courierData?.map(({ courier, awb }, i) => {
            const data = courierData?.filter(
              (el) =>
                el.courier === courierData?.[0].courier &&
                el.awb === courierData?.[0].awb
            );
            const flag = data.length === courierData?.length;
            if (prop.hold) {
              return <></>;
            }
            return (
              <Box key={i} w="180px" p="0px 5px" hidden={i > 0 && flag}>
                <Text fontWeight="bold" color="red" hidden={flag}>
                  {courier && `Product ${i + 1}`}
                </Text>
                <Text textAlign={"center"}>
                  {courier == ""
                    ? "Not Generated Yet"
                    : courier && capitalizeWords(courier)}
                </Text>
              </Box>
            );
          })}
        </td>
      )}
    </tr>
  );
};
export default React.memo(TableCard);
