import AllRoutes from "./Router/AllRoutes";
// import useGeolocationWithAllowedLocations from "./Utils/useGeoLocation";
import "./index.css";
function App() {
  // const allowedLocations = [{ latitude: 22.5345536, longitude: 88.4146176 }];

  // const { location, error, isAllowed, setIsAllowed } =
  //   useGeolocationWithAllowedLocations(allowedLocations, 50);
  // console.log(location);
  return (
    <div>
      <AllRoutes />
    </div>
  );
}

export default App;
