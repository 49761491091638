import socketIOClient from "socket.io-client";
const sockets = socketIOClient(process.env.REACT_APP_BACKEND_URL);
// Log connection events
sockets.on("connect", () => {
  console.log("Socket connected:", sockets.id);
});

sockets.on("disconnect", () => {
  console.log("Socket disconnected");
});
export { sockets };