import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  Text,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Link } from "react-router-dom";
import logo from "../Resources/logo.png";
import { allPaymentData } from "../Redux/PurchaseReducer/Action";
import { exclude_stage } from "../universal_variable";
import { capitalizeWords } from "../Component/capitalWords";
import CustomSlider from "../Component/CustomSlider";
import "../Component/Payment.css";
import { logout } from "../Redux/AuthReducer/Action";
import ProfileButton from "../Component/ProfileButton";
import { sockets } from "../emitter";

const Payment = () => {
  const dispatch = useDispatch();
  const date = new Date();
  const currentYear = date.getFullYear();
  const options = { month: "long" };
  const currentMonth = new Intl.DateTimeFormat("en-US", options).format(date);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = useSelector((store) => store.auth.token);
  const paymentData = useSelector((store) => store.purchase.allPayment);
  const [year, setYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(
    currentMonth.toLowerCase()
  );
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 8;
  const uniqueYears = new Set();
  const months = {
    january: "01",
    february: "02",
    march: "03",
    april: "04",
    may: "05",
    june: "06",
    july: "07",
    august: "08",
    september: "09",
    october: "10",
    november: "11",
    december: "12",
  };

  useEffect(() => {
    setFilteredData(
      paymentData.filter((e) => {
        const date = e.dates.orderDate.split("/");
        date.shift();
        if (date.join("/") == `${months[selectedMonth]}/${selectedYear}`) {
          return true;
        }
      })
    );
    setCurrentPage(1);
  }, [selectedYear, selectedMonth]);
  useEffect(() => {
    dispatch(allPaymentData(token));
    paymentData.map((e) => {
      uniqueYears.add(e.dates.orderDate.split("/")[2]);
      setYear(Array.from(uniqueYears));
    });
  }, [dispatch, token]);

  useEffect(() => {
    const pageFromUrl = searchParams.get("page");
    if (currentPage != Number(pageFromUrl)) {
      if (pageFromUrl) {
        setCurrentPage(Number(pageFromUrl));
      } else {
        setCurrentPage(1);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    sockets.on("dataUpdate", () => {
      
    });
    return () => {
      sockets.disconnect();
    };
  }, []);

  useEffect(() => {
    setSearchParams({ page: currentPage });
  }, [currentPage, setSearchParams]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredData.slice(indexOfFirstOrder, indexOfLastOrder);

  const totalPages = Math.ceil(filteredData.length / ordersPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <Box p={["10px", "10px", "10px", "10px 100px"]}>
      <Box
        mb={"10px"}
        position={"sticky"}
        top={0}
        bgColor={"#FAF9F6"}
        mt={"-10px"}
        p={"8px 5px"}
      >
        <Flex justifyContent={"space-between"}>
          <Link to={"/"}>
            <Image h={"40px"} src={logo} alt="logo" />
          </Link>

          <Center hidden={window.innerWidth <= 800}>
            <Text fontWeight={"bold"} fontSize={"20px"} textAlign={"center"}>
              Payment Reports
            </Text>
          </Center>
          <ProfileButton handleLogin={handleLogout} />
        </Flex>
        <Flex mt={"10px"} gap={"10px"} w={["100%", "100%", "30%", "30%"]}>
          <Tooltip label={"Filter Year"} hasArrow placement="top">
            <Select
              bgColor={"white"}
              value={selectedYear}
              onChange={(e) => {
                setSelectedYear(e.target.value);
              }}
            >
              <option hidden>Select Year</option>
              {year.map((w) => {
                return <option value={w}>{w}</option>;
              })}
            </Select>
          </Tooltip>
          <Tooltip label={"Filter Month"} hasArrow placement="top">
            <Select
              bgColor={"white"}
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e.target.value);
              }}
            >
              <option hidden>Select Month</option>
              <option value="january">JANUARY</option>
              <option value="february">FEBRUARY</option>
              <option value="march">MARCH</option>
              <option value="april">APRIL</option>
              <option value="may">MAY</option>
              <option value="june">JUNE</option>
              <option value="july">JULY</option>
              <option value="august">AUGUST</option>
              <option value="september">SEPTEMBER</option>
              <option value="october">OCTOBER</option>
              <option value="november">NOVEMBER</option>
              <option value="december">DECEMBER</option>
            </Select>
          </Tooltip>
        </Flex>
      </Box>
      <Center hidden={filteredData.length > 0}>No Data To Show</Center>
      <table style={{ width: "100%" }} hidden={filteredData.length <= 0}>
        <thead>
          <tr>
            <th style={{ width: "20%" }}>Order Data</th>
            <th style={{ width: "40%" }}>Order Description</th>
            <th border={"1px solid black"}>Payment Proof</th>
            <th style={{ width: "20%" }}>Order Date</th>
          </tr>
        </thead>
        <tbody>
          {currentOrders.map((ele) => {
            const genLink = `https://rumaenterprise.com/track/?id=${ele?.trackingID}`;
            return (
              <tr key={ele?.trackingID}>
                <td border="1px solid black" p="10px">
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px" mt="10px">
                      <Center>Dealer/School:</Center>
                    </Text>
                    <Box textAlign={"center"}>
                      {ele?.orderData?.dealer?.distributor_name}(
                      {ele?.orderData?.dealer?.distributor_code})
                    </Box>
                  </Flex>
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px">
                      <Center>Address:</Center>
                    </Text>
                    <Text>
                      <Center>{ele?.orderData?.dealer?.address}</Center>
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px">
                      <Center>Order taken by:</Center>
                    </Text>
                    <Text TextAlign="center">
                      <Center>{ele?.orderData?.orderBy?.biller_name}</Center>
                    </Text>
                  </Flex>
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px">
                      <Center>tracking ID:</Center>
                    </Text>
                    <Text
                      TextAlign="center"
                      fontSize="20px"
                      color="red"
                      fontWeight="bold"
                    >
                      <Center>{ele?.trackingID}</Center>
                    </Text>
                  </Flex>
                  <Box textAlign={"center"}>
                    <Link to={genLink} target="_blank">
                      <Text
                        fontSize="14px"
                        color="blue"
                        TextDecoration="underline"
                        fontWeight="bold"
                      >
                        {genLink}
                      </Text>
                    </Link>
                  </Box>
                </td>
                <td>
                  <Box h={"400px"} overflow={"scroll"} overflowX={"hidden"}>
                    {ele.description?.map((elem, index) => {
                      const finalStage = elem?.stage?.filter(
                        (e) => exclude_stage.includes(e.stage) === false
                      );
                      const last_stage =
                        finalStage[finalStage.length - 1]?.stage;
                      return (
                        <Box key={index} mr="10px" borderBottom={ele.description.length-1==index?null:"1px solid gray"}>
                          <Flex mt="5px" justifyContent="space-between">
                            <Center>
                              <Text>
                                <Text
                                  as={"span"}
                                  m="10px"
                                  mr="0px"
                                  color="#218560"
                                  fontWeight="bold"
                                >
                                  Product {elem.position + 1}
                                </Text>
                              </Text>
                            </Center>
                          </Flex>

                          <Text
                            m="10px"
                            mr="0px"
                            color="red"
                            fontWeight="bold"
                            fontSize="20px"
                            hidden={last_stage !== "hold"}
                          >
                            {finalStage[finalStage.length - 1].reason
                              ?.toLowerCase()
                              ?.includes("hold")
                              ? ""
                              : "Hold Due to"}{" "}
                            {capitalizeWords(
                              finalStage[
                                finalStage.length - 1
                              ].reason?.toLowerCase()
                            )}
                          </Text>
                          <Text maxW={"400px"} m="10px">
                            {elem?.title}
                          </Text>
                          <Text
                            maxW={"400px"}
                            mt="15px"
                            m="10px"
                            whiteSpace="pre-wrap"
                          >
                            {elem?.description}
                          </Text>
                          <CustomSlider data={elem?.image} />
                        </Box>
                      );
                    })}
                  </Box>
                </td>
                <td>
                  <Text textAlign={"center"}>Payment taken by {ele?.paymentimg?.length<=0?"Cash":"Online"}</Text>
                  <SimpleGrid
                    ml={"10px"}
                    pb={"10px"}
                    pt={"10px"}
                    columns={[3, 3, 3, 4]}
                    gap={"10px"}
                  >
                    {ele?.paymentimg?.map((e) => {
                      const url = `${process.env.REACT_APP_BACKEND_URL}/paymentimage/${e}`;
                      return (
                        <Popover key={e}>
                          <PopoverTrigger>
                            <Image
                              cursor={"pointer"}
                              maxH={"150px"}
                              src={url}
                              alt={e}
                            />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                              <Link to={url} target="_blank">
                                <Image src={url} alt={e} />
                              </Link>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      );
                    })}
                  </SimpleGrid>
                </td>
                <td>
                  <Center>{ele.dates.orderDate}</Center>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Flex mt="20px" justifyContent="center" hidden={filteredData.length <= 0}>
        <Button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Center>
          <Text mx="10px">
            Page {currentPage} of {totalPages}
          </Text>
        </Center>
        <Button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default Payment;
