import CryptoJS from "crypto-js";

const usePrivate = () => {
  const sec_enc_key = process.env.REACT_APP_SEC_ENC_KEY;
  const encryptData = (data) => {
    try {
      const key = CryptoJS.SHA256(sec_enc_key).toString();
    const iv = CryptoJS.SHA256(sec_enc_key).toString().slice(0, 16);
  
    // Encrypt the data (convert object to JSON string first)
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      key, // Convert the key to CryptoJS format
      { iv: iv }
    );
    return encrypted.toString();
    } catch (error) {
      console.log(error)
    }
  };
  
  // Decrypt data
  const decryptData = (encryptedData) => {
    const key = CryptoJS.SHA256(sec_enc_key).toString(); // 32 bytes key (AES-256)
    const iv = CryptoJS.SHA256(sec_enc_key).toString().slice(0, 16); // 16 bytes IV (AES block size)
  
    // Decrypt the data
    const decrypted = CryptoJS.AES.decrypt(
      encryptedData,
      key, // Convert the key to CryptoJS format
      { iv: iv }
    );
  
    // Convert the decrypted data to a UTF-8 string
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  
    // Parse the decrypted string as JSON
    return JSON.parse(decryptedString);
  };

  const isEmpty = (value) => {
    if (typeof value === "string") {
      return value.trim().length === 0;
    } else if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "object" && value !== null) {
      return Object.keys(value).length === 0;
    } else {
      return value === null || value === undefined;
    }
  };

  const KMS = (data, method) => {
    try {
      if (data == undefined || method == undefined) {
        return "Parameter Missing.";
      }
      if (isEmpty(data)) {
        return "invalid data";
      }
      switch (method) {
        case 1:
          return encryptData(data);
        case 2:
          return decryptData(data);
        default:
          return "Invalid Method";
      }
    } catch (error) {
      console.log(error);
    }
  };
  return {
    KMS,
  };
};

export default usePrivate;
