import {
  Box,
  Center,
  Flex,
  Image,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import logo from "../Resources/logo.png";
import ProfileButton from "../Component/ProfileButton";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../Redux/AuthReducer/Action";
import axios from "axios";

const HowToVideo = () => {
  const [videoData, setVideoData] = useState([]);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/videos`)
        .then((res) => {
          console.log(res.data);
          setVideoData(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Box p={"20px 10px"}>
      <Flex justifyContent={"space-between"} mb={"10px"}>
        <Link to={"/"}>
          <Image h={"40px"} src={logo} alt="logo" />
        </Link>

        <ProfileButton handleLogin={handleLogout} />
      </Flex>
      <Tabs variant="enclosed">
        <TabList>
          {videoData.length > 0 &&
            videoData.map((e) => {
              return <Tab>{e.name}</Tab>;
            })}
        </TabList>
        <TabPanels>
          {videoData.length > 0 &&
            videoData.map((e) => {
              return (
                <TabPanel>
                  <SimpleGrid columns={[1, 2, 3]} gap={"10px"}>
                    {e.data.length > 0 &&
                      e.data.map((el, index) => {
                        return (
                          <Box key={index}>
                            <Center>
                              <iframe
                                sandbox="allow-popups-to-escape-sandbox"
                                width="300"
                                height="200"
                                style={{ border: "none" }}
                                src={`${process.env.REACT_APP_BACKEND_URL}/videos/${e.name}/${el}`}
                                title={el}
                                allowFullScreen
                              ></iframe>
                            </Center>
                            <Text textAlign={"center"}>
                              {el.replaceAll("_", " ").split(".")[0]}
                            </Text>
                          </Box>
                        );
                      })}
                  </SimpleGrid>
                </TabPanel>
              );
            })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default HowToVideo;
