import axios from "axios";
import * as types from "./ActionTypes";

export const getUserList = (token) => (dispatch) => {
  try {
    dispatch({ type: types.USER_REQUEST });
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/admin/alluser`,{
        headers:{
          Authorization:`Bearer ${token}`
        }
      })
      .then((res) => {
        dispatch({ type: types.USER_SUCCESS, payload: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        dispatch({ type: types.USER_FAILURE });
      });
  } catch (error) {
    console.log(error);
  }
};

export const updateAndCreatePermission = (payload, token) => (dispatch) => {
  try {
    axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/updatepermission`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    console.log(error);
  }
};