import {
  Box,
  Button,
  Center,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FaBell } from "react-icons/fa";
import { VscBellDot } from "react-icons/vsc";
import { sockets } from "../emitter";
import {
  addToNotification,
  updateLastNotificationID,
  updateNotification,
} from "../Redux/AppReducer/Action";
import { capitalizeWords } from "./capitalWords";
import Permissions from "./Permissions";
const ProfileButton = ({ handleLogin }) => {
  const {
    isOpen: isUpdateOpen,
    onOpen: onUpdateOpen,
    onClose: onUpdateClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const user = useSelector((store) => store.auth.user);
  const notify = useSelector((store) => store.app.notification);
  const notifyID = useSelector((store) => store.app.lastNotifyID);
  const rawAlert = useSelector((store) => store.app.rawAlert);
  const dispatch = useDispatch();
  const localS = useSelector((store) => store.app.notifyData);
  useEffect(() => {
    sockets.on("notification", (data) => {
      dispatch(updateNotification(true));
      if (localS.length <= 0) {
        dispatch(addToNotification(data));
      } else {
        const id = localS[localS.length - 1].id;
        let newData = data.filter((e) => e.id > id);
        localS.forEach((e) => {
          e.read = true;
          newData.push(e);
        });
        newData = newData.sort((a, b) => b.id - a.id);
        dispatch(addToNotification(newData));
      }
    });
    sockets.on("first_notification", (data) => {
      if (data.length > localS.length) {
        dispatch(updateNotification(true));
        if (localS.length <= 0) {
          dispatch(addToNotification(data));
        } else {
          const id = localS[localS.length - 1].id;
          let newData = data.filter((e) => e.id > id);
          localS.forEach((e) => {
            e.read = true;
            newData.push(e);
          });
          newData = newData.sort((a, b) => b.id - a.id);
          dispatch(addToNotification(newData));
        }
      }
    });
  }, []);
  return (
    <Flex>
      <Permissions isUpdateOpen={isUpdateOpen} onUpdateClose={onUpdateClose} />
      <Menu
        hidden={user.first_name == undefined}
        placement="bottom-end"
        zIndex={1000}
      >
        <MenuButton
          fontSize={"12px"}
          as={Button}
          hidden={user.first_name == undefined}
        >
          {user.first_name}
        </MenuButton>
        <MenuList fontSize={"14px"}>
          {user.role == "admin" ? (
            <MenuItem
              _hover={{ bgColor: "blue", color: "white" }}
              onClick={() => {
                onUpdateOpen();
              }}
            >
              User Permissions
            </MenuItem>
          ) : null}
          <MenuItem
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/purchase")}
          >
            Add Purchase
          </MenuItem>
          <MenuItem
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/sales")}
          >
            Add Sales
          </MenuItem>
          <MenuItem
            hidden={window.innerWidth <= 1000}
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/products")}
          >
            Products
          </MenuItem>
          <MenuItem
            hidden={window.innerWidth <= 1000}
            _hover={{ bgColor: "green", color: "white" }}
            onClick={() => navigate("/feedback")}
          >
            Client Feedback
          </MenuItem>
          <MenuItem
            _hover={{ bgColor: "orange", color: "white" }}
            onClick={() => {
              navigate("/how-to-videos");
            }}
          >
            How To Use?
          </MenuItem>
          <MenuItem
            _hover={{ bgColor: "red", color: "white" }}
            onClick={handleLogin}
          >
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
      <Popover
        placement="top-end"
        size={"xl"}
        arrowSize={25}
        arrowPadding={5}
        onClose={() => {
          if (localS?.length > 0) {
            dispatch(updateLastNotificationID(localS[0]?.id));
          }
        }}
      >
        <PopoverTrigger>
          <Center
            h={"40px"}
            cursor={"pointer"}
            p={"0px 10px"}
            onClick={() => {
              dispatch(updateNotification(false));
            }}
          >
            {notify ? <VscBellDot /> : <FaBell />}
          </Center>
        </PopoverTrigger>
        <PopoverContent w={window.innerWidth <= 800 ? "370px" : "500px"}>
          <PopoverArrow />
          <PopoverBody>
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab pt={"5px"} pb={"5px"}>
                  <Flex gap={"8px"}>
                    <Text>New Order</Text>
                    <Text
                      bgColor={"blue"}
                      p={"2px 5px"}
                      color={"white"}
                      borderRadius={"50%"}
                    >
                      {localS?.length?.toString()?.padStart(2, "0")}
                    </Text>
                  </Flex>
                </Tab>
                <Tab pt={"5px"} pb={"5px"}>
                  <Flex gap={"8px"}>
                    <Text>Raw Meterial Alert</Text>
                    <Text
                      bgColor={"blue"}
                      p={"2px 5px"}
                      color={"white"}
                      borderRadius={"50%"}
                    >
                      {rawAlert.length.toString().padStart(2, "0")}
                    </Text>
                  </Flex>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  {localS?.length <= 0 ? <Text>No Notification</Text> : null}
                  <Box
                    fontSize={"15px"}
                    h={"150px"}
                    overflow={"scroll"}
                    overflowX={"hidden"}
                  >
                    {localS?.map((e, i) => {
                      const trackingPattern = /RMP\d+/;

                      const trackingMatch = e?.msg?.match(trackingPattern);

                      if (!trackingMatch) return null;

                      const trackingNumber = trackingMatch[0];
                      const trackingIndex = e?.msg?.indexOf(trackingNumber);

                      const beforeTracking = e?.msg?.substring(
                        0,
                        trackingIndex
                      );
                      const afterTracking = e?.msg?.substring(
                        trackingIndex + trackingNumber.length
                      );
                      return (
                        <Text
                          key={i}
                          borderBottom={
                            localS.length - 1 == i ? null : "1px solid black"
                          }
                        >
                          <Text
                            as="span"
                            fontWeight="bold"
                            color="red.500"
                            hidden={notifyID >= e.id}
                          >
                            (NEW)
                          </Text>{" "}
                          {beforeTracking}
                          {trackingMatch && (
                            <Text as="span" fontWeight="bold" color="teal.500">
                              {trackingMatch}
                            </Text>
                          )}{" "}
                          by{" "}
                          <Text as="span" fontWeight="bold" color="teal.500">
                            {afterTracking.split("by")[1].trim()}
                          </Text>{" "}
                          on
                          <Text as="span" fontWeight="bold" color="teal.500">
                            {" "}
                            {e.date}
                          </Text>{" "}
                          at
                          <Text as="span" fontWeight="bold" color="teal.500">
                            {" "}
                            {e.time}
                          </Text>
                        </Text>
                      );
                    })}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box
                    fontSize={"15px"}
                    h={"150px"}
                    overflow={"scroll"}
                    overflowX={"hidden"}
                  >
                    {rawAlert?.length > 0 &&
                      rawAlert?.map((e, i) => {
                        return (
                          <Text mb={"5px"}>
                            <Text as={"span"} fontWeight={"bold"} mr={"5px"}>
                              {i + 1}.
                            </Text>
                            The stock for the
                            <Text
                              as={"span"}
                              fontWeight={"bold"}
                              mr={"3px"}
                              ml={"3px"}
                              color="teal.500"
                            >
                              {e.name}
                            </Text>
                            (Product ID:{" "}
                            <Text
                              as={"span"}
                              fontWeight={"bold"}
                              color="teal.500"
                            >
                              {e.code}
                            </Text>
                            ) is currently at
                            <Text
                              as={"span"}
                              fontWeight={"bold"}
                              mr={"3px"}
                              ml={"3px"}
                              color="red.500"
                            >
                              {e.quantity}{" "}
                              {capitalizeWords(
                                e.unit.split("-")[1].toLowerCase()
                              )}
                            </Text>
                          </Text>
                        );
                      })}
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};

export default ProfileButton;
