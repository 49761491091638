import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Input,
  Stack,
  useToast,
  Select,
  Flex,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import coupon from "../Resources/coupon_blank.jpg";
import { useDispatch, useSelector } from "react-redux";
import {
  couponDataUpdate,
  postCouponDetails,
} from "../Redux/AppReducer/Action";

const CouponGenerate = ({ isCouponGenerateOpen, onCouponGenerateClose }) => {
  const user = useSelector((store) => store.auth.user);
  const token = useSelector((store) => store.auth.token);
  const couponData = useSelector((store) => store.app.couponData);
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const [image, setImage] = useState(coupon);
  const toast = useToast();
  const drawTextOnImage = () => {
    const canvas = canvasRef.current;
    if (!canvas) return; // Ensure canvas is not null
    const ctx = canvas.getContext("2d");
    if (!ctx) return; // Ensure context is not null
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      couponData.forEach(
        (
          {
            text,
            x,
            y,
            rotate = 0,
            font = "bold 50px Sans-Serif",
            color = "white",
          },
          i
        ) => {
          ctx.save();
          ctx.rotate((rotate * Math.PI) / 180);
          ctx.font = font;
          ctx.fillStyle = color;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          if (text?.length > 27) {
            const splittedText = [];
            let temp = "";
            text.split("").forEach((ele) => {
              if (temp.length === 27) {
                splittedText.push(temp);
                temp = ele;
              } else {
                temp += ele;
              }
            });
            splittedText.push(temp);
            splittedText.forEach((ele, i) => {
              ctx.fillText(ele, x, Number(y) + i * 50);
            });
          } else {
            if (i == 2) {
              ctx.fillText(text <= 9 ? `0${text}` : text, x, y);
            } else if (i == couponData.length - 2) {
              ctx.fillText(`${text}/- or above`, x, y);
            } else {
              ctx.fillText(text, x, y);
            }
          }
          ctx.restore(); // Restore state
        }
      );
    };

    img.src = image;
  };
  useEffect(() => {
    drawTextOnImage();
  }, [couponData]);
  const handleAddText = (value, pos) => {
    dispatch(couponDataUpdate(couponData, pos, value));
    drawTextOnImage();
  };
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
  
    return new Blob([arrayBuffer], { type: 'image/png' });
  };
  const handleDownload = () => {
    const blankFilter = couponData.filter(
      (e) => e.text == "" || e.text == undefined
    );
    if (blankFilter.length > 0) {
      toast({
        title: "Field Missing",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const date = new Date(couponData[3].text.split("-").reverse().join("/"));
    const payload = {
      trackingID: couponData[0].text,
      couponValue: couponData[1].text,
      timesOfUse: couponData[2].text,
      issueDate: couponData[3].text,
      minOrderValue: couponData[4].text,
      customerName: couponData[5].text,
      generatedBy: user.first_name,
      expDate: date
        .toISOString(date.setFullYear(date.getFullYear() + 1))
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-"),
    };
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });
    const canvas = canvasRef.current;
    if (!canvas) return;
    const imageBlob = dataURItoBlob(canvas.toDataURL("image/png"));
    const timestamp = Date.now();
    const imageName = `${couponData[0].text}_${timestamp}.png`;
    formData.append("couponImage", imageBlob, imageName);
    formData.append("couponImageName", imageName);
    dispatch(postCouponDetails(formData, token));
    const link = document.createElement("a");
    link.href = canvas.toDataURL("image/png");
    link.download = couponData[0].text;
    link.click();
  };

  return (
    <Modal
      isCentered
      isOpen={isCouponGenerateOpen}
      onClose={onCouponGenerateClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Generate Coupon</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Input
              disabled
              value={couponData[0].text}
              placeholder="Tracking ID"
            />
            <Input disabled value={couponData[5].text} placeholder="Name" />
            <Flex gap={"10px"}>
              <Input
                onChange={(e) => handleAddText(e.target.value, 1)}
                placeholder="Discount Amount"
              />
              <Select
                onChange={(e) => {
                  let value = couponData[1].text;
                  if (value[value.length - 1] == "%") {
                    value = value.replace("%", "");
                  }
                  if (value[0] == "₹") {
                    value = value.replace("₹", "");
                  }
                  if (e.target.value == "%") {
                    value += "%";
                  } else {
                    value = `₹${value}`;
                  }
                  handleAddText(value, 1);
                }}
              >
                <option hidden>Discount Type</option>
                <option value={"₹"}>₹</option>
                <option value={"%"}>%</option>
              </Select>
            </Flex>
            <Input
              type="number"
              onChange={(e) => handleAddText(e.target.value, 2)}
              placeholder="Times of use"
            />
            <Input
              type="number"
              onChange={(e) => handleAddText(e.target.value, 4)}
              placeholder="Min. purchase value"
            />
            <canvas
              ref={canvasRef}
              style={{ height: "200px", width: "100%" }}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={handleDownload}>
            Download Coupon
          </Button>
          <Button colorScheme="red" onClick={onCouponGenerateClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CouponGenerate;
