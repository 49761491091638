import React, { useEffect, useRef, useState } from "react";
import logo from "../Resources/logo.png";
import {
  Box,
  Button,
  Image,
  Flex,
  Text,
  Input,
  useToast,
  Stack,
  SimpleGrid,
  Checkbox,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogCloseButton,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import PurchaseTable from "../Component/Purchase/PurchaseTable";
import axios from "axios";
import { logout } from "../Redux/AuthReducer/Action";
import ProfileButton from "../Component/ProfileButton";
import { Link } from "react-router-dom";
import { initialPurchase } from "../Redux/PurchaseReducer/Action";

const RawMeterialSales = () => {
  const token = useSelector((store) => store.auth.token);
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const finalDate = `${day}-${month}-${year}`;
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  hours = hours % 12;
  hours = hours ? hours : 12;
  const timeString = `${hours
    .toString()
    .padStart(2, "0")}:${minutes}:${seconds} ${amPm}`;
  const toast = useToast();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logout());
  };
  const [tableData, setTableData] = useState([]);
  const [productData, setProductData] = useState({});
  const trackingRef = useRef();
  const [warehouses, setWarehouses] = useState(16);
  const [trackingID, setTrackingID] = useState("");
  const [inputTimeout, setInputTimeout] = useState(null);
  const user = useSelector((store) => store.auth.user);
  const handleTrackingID = () => {
    clearTimeout(inputTimeout);
    const timeout = setTimeout(() => {
      setTrackingID(trackingRef.current.value);
    }, 2000);
    setInputTimeout(timeout);
  };
  const handleSales = (rawconfirm) => {
    let flag = false;
    const rawMeterial = tableData.map((e) => {
      if (e.qty > e.availableQty) {
        flag = true;
      }
      return {
        code: e.code,
        id: e.id,
        name: e.name,
        qty: e.qty,
        unit: e.unit,
      };
    });
    if (flag) {
      toast({
        title: "Stock Insufficient.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const payload = {
      rawmeterials: rawMeterial,
      lastUpdateBy: user.first_name,
      lastUpdateDate: finalDate,
      lastUpdateTime: timeString,
    };
    let desc = productData?.description;
    const position = trackingID?.split("/")?.[1] - 1;
    desc = desc?.map((ele, i) => {
      if (i === position) {
        ele.raw = payload;
      }
      return ele;
    });
    const updatedRawMeterialQuantity = tableData.map((e, i) => {
      return {
        id: e.id,
        qty: e.oldqty !== undefined ? e.qty - e.oldqty : e.qty,
      };
    });
    const rawTable = tableData
      .filter((e) => e.oldqty !== e.qty)
      .map((e) => {
        let new_qty = e.availableQty;
        if (e.oldqty == null) {
          new_qty -= Number(e.qty);
        } else if (e.oldqty > e.qty || e.oldqty == null) {
          new_qty -= Number(e.qty) - Number(e.oldqty);
        } else {
          new_qty += Number(e.oldqty) - Number(e.qty);
        }
        return {
          old_available_quantity: e.availableQty,
          product_id: e.id,
          tracking_ID: trackingID?.split("/")?.[0],
          position: trackingID?.split("/")?.[1] - 1,
          old_quantity: Number(e.oldqty) || 0,
          new_quantity: Number(e.qty),
          new_available_quantity: new_qty,
        };
      });
    const ploadData = {
      desc,
      updatedRawMeterialQuantity,
      warehouse: warehouses,
      rawconfirm: rawconfirm,
    };
    if (rawTable.length > 0) {
      ploadData.rawTable = rawTable;
    }
    if (
      ploadData.desc == undefined ||
      ploadData.updatedRawMeterialQuantity.length <= 0
    ) {
      toast({
        title: "Invalid Data. Please fill all fields",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
    } else {
      axios
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/purchase/updaterawmeterial/${productData.id}`,
          ploadData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          toast({
            title: "Added Raw Meterials in this Product/Order",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTrackingID("");
          setTableData([]);
          setProductData({});
          trackingRef.current.value = "";
          trackingRef.current.focus();
          onAlertClose();
        })
        .catch((err) => {
          onAlertClose();
          console.log(err);
          toast({
            title: "Something went wrong",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };
  useEffect(() => {
    const [id, productNo] = trackingID.split("/");
    if (id != undefined && id != "") {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/sheet/searchproduct?search=${id}&raw=true`
        )
        .then((res) => {
          setProductData(res.data.data[0]);
          const rawmet = res.data.data[0].description[
            productNo - 1
          ].raw.rawmeterials.map((e) => {
            e.oldqty = e.qty;
            return e;
          });
          setTableData(rawmet);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [trackingID]);
  useEffect(() => {
    dispatch(initialPurchase());
  }, []);
  return (
    <Box p={"20px 10px"}>
      <AlertDialog closeOnOverlayClick={false} isCentered isOpen={isAlertOpen} onClose={onAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Raw Meterial Confirmation
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>Is this direct raw meterial sale?</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                onClick={() => {
                  handleSales(true);
                }}
              >
                Yes
              </Button>
              <Button
                ml={3}
                onClick={() => {
                  handleSales(false);
                }}
              >
                No
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Flex justifyContent={"space-between"} mb={"10px"}>
        <Link to={"/"}>
          <Image h={"40px"} src={logo} alt="logo" />
        </Link>

        <ProfileButton handleLogin={handleLogout} />
      </Flex>
      <Input
        ref={trackingRef}
        isDisabled={productData.trackingID != undefined}
        defaultValue={trackingID}
        borderRadius={"0px"}
        mt={"10px"}
        onKeyDown={() => {
          handleTrackingID();
        }}
        placeholder="Scan/Type your Tracking ID"
      />
      <Flex m={"10px"} hidden={productData.trackingID == undefined}>
        <Box w={"50%"}>
          <SimpleGrid columns={3}>
            {productData?.description?.[
              trackingID?.split("/")?.[1] - 1
            ]?.image.map((e) => {
              return (
                <Image
                  h={"100px"}
                  src={`${process.env.REACT_APP_BACKEND_URL}/images/${e}`}
                />
              );
            })}
          </SimpleGrid>
        </Box>
        <Stack>
          <Text>
            Title:{" "}
            {productData?.description?.[trackingID?.split("/")?.[1] - 1]?.title}
          </Text>
          <Text>
            Description:{" "}
            {
              productData?.description?.[trackingID?.split("/")?.[1] - 1]
                ?.description
            }
          </Text>
        </Stack>
      </Flex>

      <PurchaseTable
        hidewearhouse={false}
        tableData={tableData}
        setTableData={setTableData}
        warehouses={warehouses}
        setWarehouses={setWarehouses}
        autoFocus={productData.trackingID == undefined ? false : true}
      />
      <Flex mt={"10px"}>
        <Button colorScheme="green" onClick={onAlertOpen}>
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default RawMeterialSales;
