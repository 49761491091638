import {
  Box,
  Button,
  Center,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOrUpdateInvoice } from "../Redux/AppReducer/Action";

const InvoiceAddUpdateModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const production_sheet = useSelector((store) => store.app.orderList);
  const token = useSelector((store) => store.auth.token);
  const checkedOrder = production_sheet?.filter(
    (ele) => ele.checked == true
  )?.[0];
  const [inputs, setInputs] = useState(checkedOrder?.invoice || []);
  const [modifyInput, setModifyInput] = useState(
    checkedOrder?.invoice?.map((e) => {
      return { status: "none", id: e };
    }) || []
  );

  const addInput = () => {
    setInputs([...inputs, ""]);
    setModifyInput((prev) => {
      return [...prev, { status: "add", id: "" }];
    });
  };

  const handleChange = (id, value) => {
    const newInputs = [...inputs];
    const modifyInputs = [...modifyInput];
    let modifyIndex;
    let newIndex;
    newInputs.forEach((e, index) => {
      if (e.includes(id)) {
        newIndex = index;
      }
    });
    modifyInputs.forEach((e, index) => {
      if (e.id.includes(id)) {
        modifyIndex = index;
      }
    });
    newInputs[newIndex] = value;
    console.log(modifyIndex,modifyInputs)
    if (newIndex <= checkedOrder?.invoice?.length-1) {
      modifyInputs[modifyIndex].status = "update";
      modifyInputs[modifyIndex].updatedID = value;
    } else {
      modifyInputs[modifyIndex].id = value;
    }
    console.log(modifyInputs)
    setModifyInput(modifyInputs);
    setInputs(newInputs);
  };
  const handleRemoveInvoice = (id) => {
    try {
      let modifyInputs = [...modifyInput];
      let newIndex;
      modifyInputs.forEach((e, index) => {
        if (e.id == id) {
          newIndex = index;
        }
      });
      if (newIndex <= checkedOrder?.invoice?.length - 1) {
        modifyInputs[newIndex].status = "delete";
        setModifyInput(modifyInputs);
      } else {
        setModifyInput(modifyInput.filter((e) => e.id != id));
      }

      setInputs(inputs.filter((e) => e != id));
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = () => {
    dispatch(
      addOrUpdateInvoice(
        { ids: modifyInput, trackingID: checkedOrder?.trackingID },
        token,
        toast
      )
    );
    onClose();
  };
  return (
    <Box>
      <Modal
        closeOnOverlayClick={false}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Add/Update Invoice ({checkedOrder?.trackingID})
          </ModalHeader>
          <ModalBody>
            <Center>
              <Button onClick={addInput} colorScheme="teal">
                Add Invoice ID
              </Button>
            </Center>
            <SimpleGrid columns={2} gap={"10px"} mt={"10px"}>
              {inputs.map((input, index) => (
                <Flex
                  gap={"5px"}
                  border={"1px solid blue"}
                  pr={"10px"}
                  borderRadius={"10px"}
                >
                  <Input
                    type="number"
                    onKeyDown={(e) => {
                      if (e.key == "e") {
                        e.preventDefault();
                      }
                    }}
                    border="none"
                    focusBorderColor="transparent"
                    key={index}
                    value={input}
                    onChange={(e) => handleChange(input, e.target.value)}
                    placeholder={`ID ${index + 1}`}
                  />
                  <Center mt={"-4px"} cursor={"pointer"}>
                    <DeleteIcon
                      color={"red"}
                      onClick={() => {
                        handleRemoveInvoice(input);
                      }}
                    />
                  </Center>
                </Flex>
              ))}
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="green" mr={3} onClick={handleSubmit}>
              Submit
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                setInputs([]);
                onClose();
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default InvoiceAddUpdateModal;
