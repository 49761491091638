import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { user_permission } from "../universal_variable";
import { capitalizeWords } from "./capitalWords";
import {
  getUserList,
  updateAndCreatePermission,
} from "../Redux/AdminReducer/Action";

const Permissions = ({ isUpdateOpen, onUpdateClose }) => {
  const [permission, setPermission] = useState(user_permission);
  const dispatch = useDispatch();
  const toast = useToast();
  const inputSearchRef = useRef(null);
  const token = useSelector((store) => store.auth.token);
  const user = useSelector((store) => store.auth.user);
  const dataList = useSelector((store) => store.admin.userList);
  const [filterUser, setFilterUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const handleCheckBox = (index, status) => {
    try {
      let temp = [...permission];
      temp[index].checked = status;
      setPermission(temp);
    } catch (error) {
      console.log(error);
    }
  };
  const handlePermissionUpdate = () => {
    let name = selectedUser.first_name;
    if (selectedUser.last_name != null) {
      name = `${name} ${selectedUser.last_name}`;
    }
    let permission_payload = {};
    permission.forEach((e) => {
      permission_payload[e.value] = e.checked;
    });
    dispatch(
      updateAndCreatePermission(
        {
          id: selectedUser.id,
          permissions: permission_payload,
        },
        token
      )
    );
    toast({
      title: "Permission Updated.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    dispatch(getUserList(token));
    setFilterUser([]);
    setSelectedUser({});
    onUpdateClose();
  };
  const handleFilterUser = (e) => {
    const filetr_dealer = dataList?.filter(
      (ele) =>
        ele.first_name?.toLowerCase().includes(e.toLowerCase()) ||
        ele.last_name?.toLowerCase().includes(e.toLowerCase()) ||
        ele.email?.toLowerCase().includes(e.toLowerCase())
    );
    if (e == "") {
      setFilterUser([]);
    }
    setFilterUser(filetr_dealer || []);
  };
  useEffect(() => {
    if (user.role == "admin") {
      dispatch(getUserList(token));
    }
  }, [user]);
  return (
    <Box m={"10px"} bgColor={"#F7FAFA"}>
      <Modal
        closeOnOverlayClick={false}
        size={"lg"}
        isCentered
        isOpen={isUpdateOpen}
        onClose={onUpdateClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Permission</ModalHeader>
          <ModalBody>
            <Input
              defaultValue={
                selectedUser.name != undefined ? `${selectedUser.name}` : ""
              }
              ref={inputSearchRef}
              onKeyUp={(e) => {
                if (e.key == "Backspace") {
                  setFilterUser([]);
                  inputSearchRef.current.value = "";
                  setSelectedUser({});
                }
                if (inputSearchRef.current.value == "") {
                  setFilterUser([]);
                  setSelectedUser({});
                }
              }}
              onChange={(e) => {
                handleFilterUser(e.target.value);
              }}
              placeholder="Enter Something "
            />

            <Box
              hidden={filterUser.length <= 0}
              overflow={"scroll"}
              mt={"2px"}
              borderRadius={"5px"}
              maxH={"150px"}
            >
              {filterUser &&
                filterUser?.map((e) => {
                  return (
                    <Box
                      onClick={() => {
                        inputSearchRef.current.value = `${e.first_name} ${
                          e.last_name !== null || e.last_name != undefined
                            ? e.last_name
                            : ""
                        }`;
                        setSelectedUser(e);
                        let temp;
                        if (e.permissions == undefined) {
                          temp = [...user_permission];
                        } else {
                          const newPermission = user_permission.map((el) => {
                            el.checked = e.permissions[el.value];
                            return el;
                          });
                          temp = newPermission;
                        }
                        setPermission(temp);
                        setFilterUser([]);
                      }}
                      cursor={"pointer"}
                      borderBottom={"1px solid black"}
                      bgColor={"white"}
                    >
                      <Flex direction={"column"}>
                        <Flex gap={"5px"}>
                          <Text>Name:</Text>
                          <Text>
                            {e.first_name}{" "}
                            {e.last_name !== null || e.last_name != undefined
                              ? e.last_name
                              : ""}
                          </Text>
                        </Flex>
                        <Flex gap={"5px"}>
                          <Text>Email:</Text>
                          <Text>{e.email}</Text>
                        </Flex>
                      </Flex>
                    </Box>
                  );
                })}
            </Box>
            <Tooltip label={"Email"}>
              <Input
                mt={"5px"}
                hidden={selectedUser.email == undefined}
                placeholder="Email Address"
                defaultValue={selectedUser.email}
                isDisabled
              />
            </Tooltip>
            <SimpleGrid columns={2} hidden={selectedUser.email == undefined}>
              {permission.map((e, i) => {
                return (
                  <Checkbox
                    border={"0px solid black"}
                    isChecked={e.checked}
                    onChange={(e) => {
                      handleCheckBox(i, e.target.checked);
                    }}
                    value={e.value}
                  >
                    {capitalizeWords(e.name)}
                  </Checkbox>
                );
              })}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                setFilterUser([]);
                setSelectedUser({});
                onUpdateClose();
              }}
            >
              Close
            </Button>
            <Button
              // disabled={selectedUser.email != undefined}
              isDisabled={selectedUser.email == undefined}
              onClick={handlePermissionUpdate}
              colorScheme={"green"}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default React.memo(Permissions);
